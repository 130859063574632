import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  showAll() {
    let fullText = this.element.getAttribute('data-full-text')
    this.element.innerHTML = fullText;
  }
}