// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("trix")
require("@rails/actiontext")
import '../stylesheets/app.scss';
import Dropzone from "dropzone/dist/dropzone-amd-module";
import 'dropzone/dist/min/dropzone.min.css';
import 'dropzone/dist/min/basic.min.css';
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.min.css';
import Choices from 'choices.js';
import Chart from 'chart.js/auto';
import Sortable from 'sortablejs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

window.Choices = Choices;
window.Dropzone = Dropzone;
window.Chart = Chart;
window.Sortable = Sortable;
Dropzone.autoDiscover = false;

window.Chart.register(ChartDataLabels);

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
ActiveStorage.start()
import "controllers"

window.Rails = Rails;

Rails.delegate(document, Rails.linkDisableSelector,   "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:submit-end", Rails.enableElement)

Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-start", Rails.disableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-end", Rails.enableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:before-cache", Rails.enableElement)