import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  updateDropdown() {
    let replaceableId = this.element.getAttribute('data-old-id');
    let newId = this.element.getAttribute('data-new-id');

    let url = window.location.href.replace(replaceableId, newId);
    window.location.href = url;
  }
}
