import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let _this = this;

    if(document.getElementById('basket-batch-share')) {
      this.currentShareUrl = document.getElementById('basket-batch-share').href;
    }

    const download_acknowledgement = document.getElementById("download_acknowledgement");
    download_acknowledgement.addEventListener('change', function(e){
      var downloadButtons = document.getElementsByClassName("download-button")
      downloadButtons.forEach((element)=> {
        element.classList.toggle("isDisabled");
      })
    });

    this.basketItemCheckboxes = document.querySelectorAll('*[id^="basket_item_"]');

    this.basketItemCheckboxes.forEach((item)=> {
      item.addEventListener('change', function(){
        _this.displayBatchActionBar();
      });
    });
  }

  displayBatchActionBar() {
    let selectedItems = []
    this.basketItemCheckboxes.forEach((item)=> {
      if(item.checked) {
        selectedItems.push(item.value)
      }
    });

    if(selectedItems.length > 0){
      document.getElementById('basket-batch-share').classList.remove("hidden");
    } else {
      document.getElementById('basket-batch-share').classList.add("hidden");
    }

    let param = "";

    selectedItems.forEach((selectedItemId)=>{
      param += `&basket_item_ids[]=${selectedItemId}`
    });
    
    
    let sharedBasketUrl = document.getElementById('basket-batch-share').getAttribute('data-href');
    sharedBasketUrl = encodeURI(sharedBasketUrl + '?' + param);
    let shareUrl = ('/basket_items/shared' + '?' + sharedBasketUrl);
    document.getElementById('basket-batch-share').href = 'shares/new?share[url]=' + encodeURIComponent(sharedBasketUrl) + '';

  }
}