import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  closeModal() {
    const elem = document.getElementById(`batch-delete-modal`);
    elem.style.display = 'none';
    alert('sad');
  }
}