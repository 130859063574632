import { Controller } from "stimulus"
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  connect() {
    let _this = this;
    _this.unsavedForm = false;

    document.querySelectorAll(".styled-select").forEach((item) => {
      this.selectItems = [];
      this.selectItems.push(new Choices(item, {
        searchEnabled: false,
        shouldSort: false,
        itemSelectText: '',
        dropdownDirection: "down"
      }));
    });

    document.getElementsByClassName('input-title').forEach((item)=>{
      item.addEventListener('focusout', function(){
        if( document.getElementById('save-title')) {
          document.getElementById('save-title').click();
        }
      });
    });

    const mediaTypeCheckboxes = document.getElementsByName('project[media_type]');
    mediaTypeCheckboxes.forEach((item)=>{
      item.addEventListener('change', function() {
        document.getElementsByName('project[media_type_ids][]').forEach((checkboxItem)=> {
          checkboxItem.checked = false;
        });
        
        _this.resetAssetCategories();

        if (item.value == 'event') {
          document.getElementById(`category-Event-creative`).classList.remove("hidden");
          document.getElementById(`category-Event-proof`).classList.remove("hidden");
        }
      });
    });

    const carlineRadioButtons = document.getElementsByName('project[carline]');
    carlineRadioButtons.forEach((item)=>{
      item.addEventListener('change', function() { 
        _this.resetCarlineItems();
      });
    });

    const marketCheckboxes = document.getElementsByName('project[media_type_ids][]');
    marketCheckboxes.forEach((item)=>{
      console.log(item);
      item.addEventListener('change', function() {
        if(item.checked) {
          document.getElementById(`category-${item.value}-creative`).classList.remove("hidden");
          document.getElementById(`category-${item.value}-proof`).classList.remove("hidden");
        } else {
          document.getElementById(`category-${item.value}-creative`).classList.add("hidden");
          document.getElementById(`category-${item.value}-proof`).classList.add("hidden");
        }
      });
    });    

    var form = document.getElementById("project-form");

    form.addEventListener("input", function () {
      _this.unsavedForm = true;
    });
  }

  saveForms() {
    document.getElementById('save-basic-info').click();

    setTimeout(() => {
      Turbo.visit(this.element.getAttribute('data-show-url'));
    }, 500);
  }

  resetCarlineItems() {
    let items = document.getElementsByClassName('c-carline-item')
    items.forEach((item)=> {
      item.checked = false;
    })
  }

  resetAssetCategories() {
    let items = document.getElementsByClassName('c-asset-category')
    items.forEach((item)=> {
      item.classList.add("hidden");
    })
  }
}