import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  showModal() {
    const elem = document.getElementById(`confirmation-modal`);
    elem.style.display = 'block';
    document.getElementById(`confirmation-modal__title`).innerHTML = this.element.getAttribute('data-modal-title');
    document.getElementById(`confirmation-modal__description`).innerHTML = this.element.getAttribute('data-modal-description');
    document.getElementById(`confirmation-modal__description`).innerHTML = this.element.getAttribute('data-modal-description');
    document.getElementById(`confirmation-modal__cta`).href = this.element.getAttribute('data-modal-target');
  }

  closeModal() {
    const elem = document.getElementById(`confirmation-modal`);
    elem.style.display = 'none';
  }
}