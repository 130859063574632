import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let el = this.element
    let _this = this;
    var sortable = new Sortable(el, {
      draggable: ".filter-draggable-item",

      onEnd: function(/**Event*/evt) {
        _this.element.querySelectorAll('.filter-draggable-item').forEach((item, index) => {
          let categoryId = item.getAttribute('data-id');

          window.Rails.ajax({
            url: `/categories/${categoryId}/update_position?position=${index}`,
            type: "patch",
            success: function(data) {
              console.log('done');
            }
          })
        })
      },
    });

    
  }
}