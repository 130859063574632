import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let _this = this;
    document.getElementsByClassName('c-dropdown__checkbox')

    document.addEventListener('change', function(e){
      document.getElementsByClassName('c-dropdown__checkbox').forEach((item)=> {
        if(item == e.target) {

        } else {
          item.checked = false;
        }
      });
    });
  }  
}
