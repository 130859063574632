import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let _this = this;
    _this.isOpen = false;

    let checkbox = this.element.querySelector('.c-dropdown__checkbox');
    if(checkbox) {
      checkbox.addEventListener('change', function(){
        if(checkbox.checked == true && _this.isOpen == true) {
          checkbox.checked = false;
          _this.isOpen = false;
        } else {
          _this.isOpen = true;
        }
        
      });
    }

    document.addEventListener("click", function(event){
      if(_this.hasSomeParentTheClass(event.target, 'c-dropdown-holder')) {
      } else {
        _this.element.querySelectorAll('.c-dropdown__checkbox').forEach((item) => {
          if(item.checked) {
            item.checked = false;

            //Special handler for notification dropdown
            if(_this.element.getAttribute('data-notifications') == 'true') {
              document.getElementById('notification_count').innerHTML = '0';

              document.querySelectorAll('.c-notification-item').forEach((item) => {
                item.classList.remove('bg-grayLight');
              });

              document.querySelectorAll('.c-notification-unread').forEach((item) => {
                item.classList.add('hidden');
              })
            }
          }
        });
      }
    });
  }

  hasSomeParentTheClass(element, classname) {
    try {
      if(element.className) {
        if (element.className.split(' ').indexOf(classname)>=0) return true;
        return element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  markAllRead() {
    window.Rails.ajax({
      url: `/notifications/mark_all_read`,
      type: "post",
      success: function(data) {
        
      }
    })
  }
}