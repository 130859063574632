import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let assetId = this.element.getAttribute('data-asset-id');
    let myDropzone = new Dropzone("div#asset-new-version", { url: `/files/${assetId}/upload`, maxFilesize: 999999999999});

    myDropzone.on("totaluploadprogress", function (progress) {
      document.getElementById('upload-button-inner').innerHTML = `Uploading ${Math.ceil(progress)}%`;
    });

    myDropzone.on('complete', function(file, progress, bytesSent) {
      document.getElementById('upload-button-inner').innerHTML = `Upload new version`;
      window.location.href = window.location.href;
    });

    flatpickr(".flatpickr", {
      enableTime: false,
    });
  }

  openFileDialog() {
    document.getElementById("asset-new-version").click();
  }
}