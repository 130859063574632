import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  clearSearch(){
    document.getElementsByClassName('c-search__input').forEach((item)=> {
      item.value = null;
    });

    document.getElementById('sidebar-search').submit();
  }
}
