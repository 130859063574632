import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let nextSibling = this.element.nextElementSibling
    if(nextSibling) {
      let count = 0;
      nextSibling.querySelectorAll('.sidebar-category__children-count').forEach((item)=> {
        count += parseInt(item.innerText);
      })

      if(count == 0) {
        this.element.style.display = 'none'
      }
    }
  }
}