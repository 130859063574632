import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.checkAllChildrenSelected();

    let _this = this;
    let parentCheckboxes = this.element.getElementsByClassName('c-project-status-filter-section__parent-checkbox');
    parentCheckboxes.forEach((item)=> {
      item.addEventListener('change', function(){
        if(item.checked) {
          _this.checkAll(true);
        } else {
          _this.checkAll(false);
        }
      });
    })
  }

  checkAll(state) {
    let checkboxes = this.element.getElementsByClassName('c-checkbox');
    checkboxes.forEach((item)=>{
      item.checked = state;
    });

    document.getElementById('sidebar-search').submit();
  }

  checkAllChildrenSelected() {
    let childrenCheckboxes = this.element.getElementsByClassName('c-checkbox__child');
    let items = []
    let sum = 0;
    childrenCheckboxes.forEach((item) => {
      sum += parseInt(item.getAttribute('data-sum'));
      items.push(item.checked);
    });

    if(items.indexOf(false) < 0) {
      let parentCheckboxes = this.element.getElementsByClassName('c-project-status-filter-section__parent-checkbox');
      parentCheckboxes.forEach((item)=> {
        item.checked = true;
      })
    }

    console.log(this.element.getElementsByClassName('c-project-status-filter-section__items-count'));
    this.element.getElementsByClassName('c-project-status-filter-section__items-count')[0].innerText = `(${sum})`;;
    
  }
}