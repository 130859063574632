import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button"]
  connect() {
  }

  reset() {
    let _this = this;
    setTimeout(() => {  
      _this.element.reset()
      _this.buttonTarget.disabled = false
    }, 200);

  }
}
