import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['assets'];
  connect() {
    let _this = this;
    _this.selectedItems = [];
    _this.metaPressed = false;

    document.addEventListener('keydown', (e) => {
      if(e.key == 'Meta') {
        _this.metaPressed = true;
      }
    });

    document.addEventListener('keyup', (e) => {
      if(e.key == 'Meta') {
        _this.metaPressed = false;
      }
    });
    
    document.getElementsByClassName('c-dropdown__checkbox').forEach((item)=> {
      item.checked = false;
    });

    document.getElementsByClassName('c-dropdown__checkbox')

    document.addEventListener('change', function(e){

      document.getElementsByClassName('c-dropdown__checkbox').forEach((item)=> {
        if(item == e.target) {

        } else {
          item.checked = false;
        }
      });
    });

    //Google Drive like drop interface
    let dropzone = document.getElementById('dropzone');
    document.addEventListener('dragover', function(e) {
      dropzone.style.display = 'flex';
      clearInterval(window.hideDropzone);
      window.hideDropzone = setInterval(function(){
        let dropzone = document.getElementById('dropzone');
        dropzone.style.display = 'none';
      }, 100);
    });

    const dropzoneProgressFileList = document.getElementById('dropzone-progress-files');

    const uploadPath = this.element.getAttribute('data-upload-path');
    // Initialize dropzone
    if(dropzone) {
      let myDropzone = new Dropzone("div#dropzone", { 
        url: uploadPath,
        maxFilesize: 100000,
        params: {'folder_id': `${this.element.getAttribute('data-folder-id')}`}
      });
      myDropzone.on("dragover", function(file, xhr, formData) {
        dropzone.style.display = 'flex';
        clearInterval(window.hideDropzone);
        window.hideDropzone = setInterval(function(){
          let dropzone = document.getElementById('dropzone');
          dropzone.style.display = 'none';
        }, 100);
      });
      
      myDropzone.on('uploadprogress', function(file, progress, bytesSent) {
        document.getElementById('dropzone-progress').style.display = 'block';
        let progressItem = dropzoneProgressFileList.querySelector(`.file-${file.upload.uuid}`);
        if(progressItem) {
          progressItem.querySelector('.status').innerText = file.status;
        } else {
          let filename = file.name.slice(0, 50)
          if(file.name.length > 50) {
            filename = `${filename}...`
          }
          dropzoneProgressFileList.innerHTML +=  `<div class="flex flex-row text-darkBlue justify-between file-${file.upload.uuid} border-bottom border-tundora"><span class="pr-2 break-all">${filename}</span><span class="status">${file.status}</span></div>`
        }
      });

      myDropzone.on('error', function(file, errormessage, xhr) {
        alert(errormessage);
        alert(JSON.stringify(xhr));
      });

      myDropzone.on("totaluploadprogress", function (progress) {
        _this.displayActiveFileUploads(myDropzone);
        document.getElementById('uploding-text').innerHTML = `Uploading`;

        document.getElementById('total-progress').innerHTML = ` – ${Math.ceil(progress)}% `;
      });

      myDropzone.on('complete', function(file, progress, bytesSent) {
        let activeUploads = _this.displayActiveFileUploads(myDropzone);

        if(activeUploads == 0) {
          document.getElementById('uploding-text').innerHTML = `Uploads`;
          document.getElementById('total-files').innerHTML = '';
          document.getElementById('total-progress').innerHTML = '';
          
        }

        document.getElementById('dropzone-progress').style.display = 'block';
        let progressItem = dropzoneProgressFileList.querySelector(`.file-${file.upload.uuid}`);
        if(progressItem) {
          progressItem.querySelector('.status').innerText = file.status;
        } else {
          let filename = file.name.slice(0, 50);
          if(file.name.length > 50) {
            filename = `${filename}...`
          }
          dropzoneProgressFileList.innerHTML +=  `<div class="flex flex-row text-darkBlue justify-between "file-${file.upload.uuid}"><span>${filename}</span><span class="status">${file.status}</span></div>`
        }
      });
    }



    this.initializeAssetActions();

    setInterval(function(){ 
      _this.initializeAssetActions();
    }, 1000);
  }

  selectItem(item) {
  }

  displayActiveFileUploads(myDropzone) {
    let activeUploads = 0;
    myDropzone.files.forEach((item)=> {
      if(item.status == "uploading") {
        activeUploads += 1;
      }
    })
    document.getElementById('total-files').innerText = `${activeUploads} file(s)`;

    return activeUploads;
  }

  initializeAssetActions() {
    let _this = this;
    this.assetSelectCheckboxes = document.querySelectorAll('*[id^="asset_checkbox_"]');

    this.assetSelectCheckboxes.forEach((item)=> {
      item.addEventListener('change', function(){
        _this.displayBatchActionBar();
      });
    });
  }

  onCreateSuccess() {
    this.initializeAssetActions();
    //document.getElementById('dropzone-progress').style.display = 'none';
  }

  openFileDialog() {
    document.getElementById("dropzone").click();
  }

  displayBatchActionBar() {
    let batchSelectedAssets = document.getElementById('batch-selected-assets');
    let batchSelectedActions = document.getElementById('batch-selected-actions');
    let batchEditLinks = document.getElementsByClassName('batch-edit-link');
    
    let items = [];
    let selectedItems = [];
    this.assetSelectCheckboxes.forEach((item)=> {
      items.push(item.checked)
      if(item.checked) {
        selectedItems.push(item.value)
      }
    });

    batchEditLinks.forEach((item)=> {
      let param = "";

      selectedItems.forEach((selectedItemId)=>{
        param += `&asset_ids[]=${selectedItemId}`
      });
      item.href = encodeURI(`${item.getAttribute('data-href')}${param}`);

      let dataTarget = item.getAttribute('data-modal-target');
      if(dataTarget) {
        item.setAttribute('data-modal-target', encodeURI(`${item.getAttribute('data-href')}${param}`));
      }
    })


    if(items.indexOf(true) > -1) {
      batchSelectedActions.style.display = 'flex';
      batchSelectedAssets.innerText = items.filter(function(item) { if(item == true) return true;}).length
    } else {
      batchSelectedActions.style.display = 'none';
    }
  }
  
}
