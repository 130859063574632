import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let _this = this;
    this.selectedResourceIds = [];
    let checkboxes = document.querySelectorAll('.selected-resource');
    checkboxes.forEach((item)=> {
      item.addEventListener('change', (event) => {
        if(this.selectedResourceIds.indexOf(item.value) > -1) {
          let index = _this.selectedResourceIds.indexOf(item.value);
          console.log(index);
          _this.selectedResourceIds.splice(index, 1);
        } else {
          _this.selectedResourceIds.push(item.value);
        }
        
        console.log(_this.selectedResourceIds);
        _this.setIndexActions();
      });
    });
  }

  setIndexActions() {
    if(this.selectedResourceIds.length > 0) {
      document.getElementById('batch-actions').classList.remove('hidden');
    } else {
      document.getElementById('batch-actions').classList.add('hidden');
    }
    let param = [];
    this.selectedResourceIds.forEach((selectedItemId)=>{
      param += `&user_ids[]=${selectedItemId}`
    });


    const batchDelete = document.getElementById('batch-delete')
    let batchDeleteUrl = batchDelete.getAttribute('href');

    const batchMarket = document.getElementById('batch-market')
    let batchMarketUrl = batchMarket.getAttribute('href');

    const batchRole = document.getElementById('batch-role')
    let batchRoleUrl = batchRole.getAttribute('href');

    batchDelete.setAttribute('href', `${batchDeleteUrl}?${param}`);
    batchMarket.setAttribute('href', `${batchMarketUrl}${param}`);
    batchRole.setAttribute('href', `${batchRoleUrl}${param}`);

  }
}