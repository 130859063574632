import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

    const tos = document.getElementById("tos");
    tos.addEventListener('change', function(e){
      var element = document.querySelector(".login-button");
      if (tos.checked  ){
        element.disabled = null;
      } else {
        element.disabled = "disabled";
      }
    });
  }
}