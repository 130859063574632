import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let projectOpen = this.element.getAttribute('data-project-open');
    let projectCosed = this.element.getAttribute('data-project-closed');

    var ctx = document.getElementById('myChart');
    var myChart = new window.Chart(ctx, {
        caleLineColor: "rgba(0,0,0,0)",
        type: 'bar',
        data: {
          labels: ['Open', 'Closed'],
          datasets: [{
            axis: 'y',
            label: '',
            data: [projectOpen, projectCosed],
            fill: false,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(201, 203, 207, 0.2)'
            ],
            borderWidth: 0
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              borderColor: 'black'
            }
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              clamp: true,
              offset: 5,
              align: 'end'
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
            }
          },
          indexAxis: 'y',
        }
    });

    let projectSubmitted = this.element.getAttribute('data-project-submitted');
    let projectSubmissionAccepted = this.element.getAttribute('data-project-submission-accepted');
    let projectCreativeAccepted = this.element.getAttribute('data-project-creative-accepted');
    let projectFordApproved = this.element.getAttribute('data-project-ford-approved');
    let projectDocumentationAccepted = this.element.getAttribute('data-project-documentation-accepted');

    var ctx2 = document.getElementById('project-pie');
    var myChart2 = new window.Chart(ctx2, {
      type: 'doughnut',
      data: {
        labels: [
          'Submitted',
          'Submission accepted',
          'Creative accepted',
          'Ford approved',
          'Documentation accepted',
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [projectSubmitted, projectSubmissionAccepted, projectCreativeAccepted, projectFordApproved, projectDocumentationAccepted],
          backgroundColor: [
            'rgb(245, 146, 38)',
            'rgb(45, 153, 255)',
            'rgb(255, 231, 0)',
            'rgb(169, 34, 217)',
            'rgb(50, 173, 6)',
          ],
          hoverOffset: 4
        }]
      },
      options: {
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: true,
            position: 'right'
          },
          title: {
            display: false,
          }
        }
      }
    });
  }
}