import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let id = this.element.getAttribute('data-id');
    var ctx = document.getElementById(`market-${id}`);

    let socialStills = parseInt(this.element.getAttribute('data-social-stills'));
    let onlineBanners = parseInt(this.element.getAttribute('data-online-banners'));
    let print = parseInt(this.element.getAttribute('data-print'));
    let ooh = parseInt(this.element.getAttribute('data-ooh'));
    let pos = parseInt(this.element.getAttribute('data-pos'));

    var myChart = new window.Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Social Stills', 'Online banners', 'Print', 'OOH', 'POS'],
          datasets: [{
            axis: 'y',
            label: '',
            barThickness: 15,
            data: [ socialStills, onlineBanners, print, ooh, pos],
            fill: false,
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 205, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(201, 203, 207, 1)'
            ],
            borderColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
              'rgb(201, 203, 207)'
            ],
            borderWidth: 0
          }]
        },
        options: {
          maintainAspectRatio: false,
          gridLines: {
            display:false,
            //color: '#d43853'
          },
          layout: {
            padding: {
              right: 30
            }
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              clamp: true,
              offset: 5,
              align: 'end'
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
            }
          },
          scales: {
            x: {
                grid:{
                 display:false
                     }
               },
            y: 
               {
             grid:{
              display:false
                  }
               }
                   },
          legend: {
            hidden: true,
            display: false
          },
          indexAxis: 'y',
        }
    });
  }
}
