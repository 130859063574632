import { Controller } from "stimulus"
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  connect() {
    let _this = this;

    document.querySelectorAll(".styled-select").forEach((item) => {
      this.selectItems = [];
      this.selectItems.push(new Choices(item, {
        searchEnabled: false,
        shouldSort: false,
        itemSelectText: '',
        dropdownDirection: "down"
      }));
    });
  }

  saveForm() {
    document.getElementById('save-widget').click();
  }
}