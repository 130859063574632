import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let _this = this;
    this.crossCarlineCheckbox= document.querySelectorAll('*[id^="cross_carline_item_"]');
    this.crossCarlineCheckbox.forEach((item)=> {
      item.addEventListener('change', function(){
        _this.getAssetsCount('cross_carline');
      });
    });

    this.singleCarlineCheckbox= document.querySelectorAll('*[id^="single_carline_item_"]');
    this.singleCarlineCheckbox.forEach((item)=> {
      item.addEventListener('change', function(){
        _this.getAssetsCount('single_carline');
      });
    });

    this.nonCarlineCheckbox= document.querySelectorAll('*[id^="non_carline_item_"]');
    this.nonCarlineCheckbox.forEach((item)=> {
      item.addEventListener('change', function(){
        _this.getAssetsCount('non_carline');
      });
    });

    this.allCarline = document.getElementById('all_carline');
    this.allCarline.addEventListener('change', function(){
      if(_this.allCarline.checked) {
        let items = document.getElementsByClassName('c-folder-item');
        items.forEach((node)=> {
          node.classList.remove('hidden');
        });

        items = document.getElementsByClassName('c-folder-category');
        items.forEach((node)=> {
          node.classList.remove('hidden');
        });
      }
    });
  }

  getAssetsCount(carline) {
    let _this = this;

    let url = ''
    if(carline == 'cross_carline') {
      url = this.buildCrossCarlineUrl();
    } else {
      url = this.buildOtherCarlineUrl(carline);
    }
    
    window.Rails.ajax({
      url: url,
      type: "get",
      success: function(data) {
        document.getElementById('cross_carline_assets_count').innerText = ` (${data.assets_count}) `
        if(data.assets_count > 0) {
          document.getElementById('cross_carline_assets_submit').disabled = false;
        } else {
          document.getElementById('cross_carline_assets_submit').disabled = true;
        }

        let assetsAggs = JSON.parse(data.assets_aggs);
        _this.hideEmptyFolders(assetsAggs);
        _this.hideEmptyAccordions();
      }
    })
  }

  hideEmptyFolders(assetsAggs) {
    document.getElementsByClassName('c-folder-item').forEach((item)=> {
      item.classList.add('hidden');
    });

    assetsAggs.forEach((item)=> {
      let node = document.getElementById(`central-campaings-folder-${item.key}`);
      if(node) {
        node.classList.remove('hidden');
      }
    });
  }

  buildCrossCarlineUrl() {
    let selectedIds = []
    this.crossCarlineCheckbox.forEach((item)=> {
      if (item.checked) {
        selectedIds.push(item.value);
      }
    });
    let selectedIdsString = ''
    selectedIds.forEach((item) => {
      selectedIdsString += `&search[category_all][]=${item}`
    });

    return `/asset_categories/central-campaigns?render_json=true&search[chip_category]=cross_carline${selectedIdsString}`;
  }

  buildOtherCarlineUrl(carline) {
    let filterParamName = 'chip_category_id_with_children';
    let selectedId = null;
    if(carline === 'single_carline') {
      this.singleCarlineCheckbox.forEach((item)=> {
        if (item.checked) {
          selectedId = item.value;
        }
      });

      filterParamName = 'chip_category_id';
    } else if(carline === 'non_carline') {
      this.nonCarlineCheckbox.forEach((item)=> {
        if (item.checked) {
          selectedId = item.value;
        }
      });

      filterParamName = 'chip_category_id_with_children';
    }

    return `/asset_categories/central-campaigns?render_json=true&search[chip_category]=${carline}&search[${filterParamName}]=${selectedId}`
  }

  hideEmptyAccordions() {
    document.getElementsByClassName('c-folder-category').forEach((folderAccordion)=> {
      let folders = []
      folderAccordion.querySelectorAll('.c-folder-item').forEach((item) => {
        folders.push(item.classList.contains('hidden'));
      });

      if(folders.indexOf(false) > -1) {
        folderAccordion.classList.remove('hidden');
      } else  {
        folderAccordion.classList.add('hidden');
      }


      folders = [];
    });
  }
}
