import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    flatpickr("#files_available_to", {
      enableTime: false,
    });

    flatpickr(".flatpickr", {
      enableTime: false,
    });

    let form = document.getElementById(`batch-edit-form`);
    if(form) {
      form.addEventListener("submit", function(){
        document.getElementById(`batch-edit-button`).value = "Saving..";
      });
    }

    let keywordCheckboxes = document.querySelectorAll('[id^="batch_edit_keyword"]');
    keywordCheckboxes.forEach((item) => {
      item.addEventListener('change', function(){
        if(item.checked) {
          let parentId = item.getAttribute('data-parent-category-id');
          document.getElementById(`batch_edit_keyword_${parentId}`).checked = true;
        }
      })
    })
  }

  disconnect() {
  }

  closeModal() {
    const elem = document.getElementById(`batch_action_modal`);
    elem.innerHTML = '';
  }
}