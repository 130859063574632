import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const dropzoneUrl = this.element.getAttribute('data-upload-url');
    const myDropzone = new Dropzone(this.element, { url: dropzoneUrl});
  }

  openFileDialog() {
    this.element.click();
  }
}