import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let nextSibling = this.element.nextElementSibling
    if(nextSibling) {
      if(nextSibling.querySelectorAll('.sidebar-category').length == 0) {
        let itemsCount = 0;
        if(this.element.querySelector('.sidebar-category__children-count')) {
          itemsCount = parseInt(this.element.querySelector('.sidebar-category__children-count').innerText);
        }
        if(itemsCount > 0) {
        } else {
          this.element.style.display = 'none';
        }
      }
    }
  }
}